import { Link } from 'gatsby';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { protocolConfig } from '../../../config';
import Button from '../../components/Button';
import Card from '../../components/Card';
import CreateProtocolBasics from '../../components/CreateProtocolBasics';
import CreateProtocolResult from '../../components/CreateProtocolResult';
import Layout from '../../components/Layout';
import OverlayBox from '../../components/OverlayBox';
import ProtocolMainImage from '../../components/ProtocolMainImage';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import { RR } from '../../utils/protocolConfig';
import { createPdf, getData, saveProtocol } from '../../utils/protocolHelpers';
import ProtocolHeader from '../../components/ProtocolHeader';
import { useInterval, useQuery } from '../../utils/hooks';

const TYPE = 'RR';

const StyledCreateProtocolRRPage = styled.div`
  form {
    .dual {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      align-items: center;
      p {
        margin-bottom: 0.5rem;
      }
    }
    h4 {
      margin-top: 2rem !important;
      margin-bottom: 1rem !important;
      font-size: 1.5rem !important;
      font-weight: 600 !important;
    }
  }
`;

const CreateProtocolRRPage = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [users, setUsers] = useState([]);
  const [reservoir, setReservoir] = useState({});
  const [customer, setCustomer] = useState({});
  const [protocolOptions, setProtocolOptions] = useState([]);
  const [readyForAutoSave, setReadyForAutoSave] = useState(false);
  const [missingInformationWarning, setMissingInformationWarning] = useState({
    active: false,
    skipValidation: false,
  });

  const [protocolData, setProtocolData] = useState({
    status: 'Ej påbörjat',
    nextRecommendedInspection: '',
    nextRecommendedCleaning: '',
    nextRecommendedFilterChange: '',
    nextRecommendedControl: '',
    dateForInspection: '',
    responsibleTechnician: '',
    orderPerson: '',
    contactPerson: '',
    waterSupply: '',
    electricSupply: '',
    robotType: '',
    resultsInner: RR.resultsInner,
  });

  const query = useQuery();

  const autoSave = async () => {
    await saveProtocol({
      dispatch,
      query,
      reservoir,
      state,
      protocolData,
      customer,
      isAutoSave: true,
    });
  };

  const { runningAction } = useInterval({
    action: autoSave,
    active: readyForAutoSave,
  });

  useEffect(() => {
    dispatch({ type: 'LOADING', value: true });
    if (state.token) {
      getData({
        dispatch,
        query,
        setReadyForAutoSave,
        setCustomer,
        setUsers,
        setReservoir,
        setProtocolOptions,
        setProtocolData,
        state,
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.token]);

  const _createPdf = async (skipValidation, skipDownload) => {
    createPdf({
      skipValidation,
      skipDownload,
      dispatch,
      query,
      setMissingInformationWarning,
      missingInformationWarning,
      protocolData,
      state,
      reservoir,
      customer,
    });
  };

  const _saveProtocol = async (e) => {
    e.preventDefault();
    saveProtocol({
      dispatch,
      query,
      reservoir,
      state,
      protocolData,
      customer,
    });
  };

  const mediaContext = useMemo(() => {
    return {
      customer: customer._id,
      reservoir: reservoir._id,
      protocol: protocolData._id || '',
    };
  }, [customer, reservoir, protocolData]);

  return (
    <Layout>
      {readyForAutoSave && (
        <StyledCreateProtocolRRPage>
          <ProtocolHeader
            date={protocolData.dateForInspection}
            createPdf={_createPdf}
            id={protocolData._id}
            query={query}
            runningAction={runningAction}
          />
          <div className="single-customer-inner">
            <div className="single-customer-left-content">
              <Card>
                <form onSubmit={_saveProtocol}>
                  <>
                    <CreateProtocolBasics
                      type={'robotrengöring'}
                      protocolData={protocolData}
                      setProtocolData={setProtocolData}
                      users={users}
                      customer={customer}
                      reservoir={reservoir}
                    />
                    <div className="dual">
                      <ProtocolMainImage
                        context={mediaContext}
                        setProtocolData={setProtocolData}
                        protocolData={protocolData}
                        imageKey={'mainImage'}
                      />
                      {protocolConfig[TYPE].firstPageDualImages && (
                        <ProtocolMainImage
                          context={mediaContext}
                          setProtocolData={setProtocolData}
                          protocolData={protocolData}
                          imageKey={'secondaryImage'}
                        />
                      )}
                      <div>
                        <p>Elförsörjning</p>
                        {/* eslint-disable-next-line jsx-a11y/no-onchange */}
                        <select
                          value={protocolData.electricSupply}
                          onChange={(e) => {
                            setProtocolData({
                              ...protocolData,
                              electricSupply: e.target.value,
                            });
                          }}
                        >
                          <option value="">Välj alternativ</option>
                          <option>1-fas uttag</option>
                          <option>3-fas uttag</option>
                          <option>Fristående elverk</option>
                        </select>
                      </div>
                      <div>
                        <p>Utförande</p>
                        {/* eslint-disable-next-line jsx-a11y/no-onchange */}
                        <select
                          value={protocolData.robotType}
                          onChange={(e) => {
                            setProtocolData({
                              ...protocolData,
                              robotType: e.target.value,
                            });
                          }}
                        >
                          <option value="">Välj alternativ</option>
                          <option>VR-600</option>
                          <option>VR-50</option>
                        </select>
                      </div>
                    </div>
                    <h4>Resultat</h4>
                    {protocolData.resultsInner.map((result, resultIndex) => (
                      <CreateProtocolResult
                        context={mediaContext}
                        key={result.key}
                        type={query.type.split(';')[1]}
                        mainKey={'resultsInner'}
                        result={result}
                        resultIndex={resultIndex}
                        protocolData={protocolData}
                        setProtocolData={setProtocolData}
                        protocolOptions={protocolOptions}
                      />
                    ))}
                  </>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <button
                    type="submit"
                    style={{ display: 'none' }}
                    id="submitNewProtocolButton"
                  ></button>
                </form>
              </Card>
            </div>
            <div className="single-customer-right-content">
              <Card>
                <div className="customer-card-header">
                  <h3>Status</h3>
                </div>
                {/* eslint-disable-next-line jsx-a11y/no-onchange */}
                <select
                  value={protocolData.status}
                  onChange={(e) => {
                    setProtocolData({
                      ...protocolData,
                      status: e.target.value,
                    });
                  }}
                >
                  <option>Ej påbörjat</option>
                  <option>Hanteras</option>
                  <option>Klart</option>
                </select>
              </Card>
            </div>
          </div>
          <OverlayBox
            header="Information saknas"
            active={missingInformationWarning.active}
            close={() => {
              setMissingInformationWarning({
                ...missingInformationWarning,
                active: false,
              });
            }}
          >
            <p>
              Viss reservoarsinformation saknas för att skapa en komplett PDF.
              Följande information krävs på reservoarskortet:
            </p>
            <p>Namn</p>
            <p>Bottenyta</p>
            <p>Area</p>
            <br />
            <p>Hur vill du fortsätta</p>
            <Link
              to={`/reservoirs/single/?customerId=${customer._id}&reservoirId=${reservoir._id}`}
              state={{
                openDialogue: 'edit',
              }}
            >
              <Button small>Komplettera information</Button>
            </Link>

            <Button
              small
              onClick={() => {
                _createPdf(true);
              }}
            >
              Fortsätt skapa PDF
            </Button>
            <Button
              small
              look="danger"
              onClick={() => {
                setMissingInformationWarning({
                  ...missingInformationWarning,
                  active: false,
                });
              }}
            >
              Avbryt
            </Button>
          </OverlayBox>
        </StyledCreateProtocolRRPage>
      )}
    </Layout>
  );
};

export default CreateProtocolRRPage;
