export const protocolConfig = {
  RR: {
    firstPageDualImages: true,
  },
  MR: {
    firstPageDualImages: true,
  },
  insp: {},
  kont: {},
};
